import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import

import { BalanceService } from "../../service/balance.service";
import { SlotService } from "../../service/slot.service";

import { ShareMenu } from "./share_menu";
import { ShareLeft } from "./share_left";


import {
  ConverMoeny,
} from "../../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login"
}

interface Props {
  user : any
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  balance: number;
  inBalance: number;
  point : number
  rolling : number
  
}

export class Point extends Component<Props, State> {
  balanceService = new BalanceService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      inBalance: 0,
      point : 0,
      rolling : 0,

    };

    this.handleUpdateInBalance()
  }


  
  handleUpdateInBalance = () => {

    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          inBalance: data.balance,
          rolling: data.rolling,
          
        });
      } else {
      }
    });
  };

  
  handleChangeToBalance = () => {
    if(this.state.balance <= 0 ){
        confirmAlert({
            title: "벨런스",
            message: "벨런스를 입력해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                },
              },
            ],
          });
          return ;
    }

    confirmAlert({
        title: "벨런스",
        message: "보유머니를 입금 하시겠습니까?",
        buttons: [
          {
            label: "확인",
            onClick: () => {

                this.balanceService.user_balance_to_point(this.state.balance).then((data: any) => {
                    if (data.status === "success") {
                      confirmAlert({
                        title: "벨런스",
                        message: "벨런스를 금고머니로 변경하였습니다.",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              window.location.reload()
                            },
                          },
                        ],
                      });
            
                    }
                });
            },
          },

          {
            label: "취소",
            onClick: () => {

            },
          },

        ],
      });
     

  };

  handleChangeToPoint = () => {
    if( this.state.point <= 0 ){
        confirmAlert({
            title: "금고머니",
            message: "금고머니를 입력해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                },
              },
            ],
          });
          return ;
    }

    confirmAlert({
        title: "금고머니",
        message: "금고머니를 출금하시겠습니까?",
        buttons: [
          {
            label: "확인",
            onClick: () => {
                this.balanceService.user_point_to_money(this.state.point).then((data: any) => {
                    if (data.status === "success") {
                        confirmAlert({
                            title: "금고머니",
                            message: "금고머니를 벨런스로 변경하였습니다.",
                            buttons: [
                              {
                                label: "확인",
                                onClick: () => {
                                  window.location.reload()
                                },
                              },
                            ],
                          });
                  }
                });
            },
          },

          {
            label: "취소",
            onClick: () => {

            },
          },

        ],
      });
     

  };



  render() {

    console.log(this.props.user)
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none"
        }}
        
        overlayStyle={{
          overflow: 'scroll'
        }}
      >
        {(close) => (
          
          <div>


<div className="modal-app show">
		<button className="modal-close-btn modal-close" data-dismiss="modal" onClick={() => {
                    this.props.handleClose();
                  }}></button>
		<a href="/" className="diamond-logo">
		   <img className="panel" src="/new/images/panel.png"/>
		   <img className="dias-lg" src="/new/images/diamond-lg.png"/>
		   <div className="dias-sm">
			  <img className="diamond-img" src="/new/images/diamond-sm.png"/>
			  <img className="glow" src="/new/images/glow.png"/>
		   </div>
		   <img className="diamond" src="/new/images/diamond.png"/>
		   <img className="slot" src="/new/images/slot.png"/>
		</a>


    <ShareMenu handleChange={this.props.handleChange}></ShareMenu>   
     

	 </div>

	 <div className="modal loginModal subpage-modal fade show" role="dialog" data-backdrop="static" aria-modal="true" style={{paddingRight:'17px',display:'block'}}>
		<div className="modal-dialog modal-dialog-centered">
		   <div className="modal-content">
			  <div className="row">
			

        <ShareLeft handleChange={this.props.handleChange} viewType={popupView.point}></ShareLeft>


				 <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
					<div className="modal-body">
					   <div className="modal-title">
						  <h6 className="title mb-0">금고</h6>
						  <h6 className="sub ml-2 mb-0">BANK</h6>
					   </div>
            
             <div className="form-container">
              <div className="form-group">
                <div className="input-container">
                  <div className="infos">
                
                  </div>
                </div>
              </div>
            </div>
            
            
            <div className="form-container">
              <div className="form-group">
                <div className="labels">
                    <span>금고입금</span>
                    {
                    this.props.user && 
                      <span style={{padding: '15px', fontSize: '16px' ,color: '#FFC107'}}> 유저 보유금 : {ConverMoeny(this.state.inBalance)}</span>
                    }
                </div>
                <div className="input-container">
                  <div className="infos">
                    <input className="form-control parsley-success" value={this.state.balance} onChange={(e) => this.setState({balance: Number(e.target.value),})} />
                  </div>
                </div>
              </div>
            </div>    

            <div className="modal-footer">
              <button type="submit" className="btn-style" onClick={this.handleChangeToBalance}>입금하기</button>
            </div> 

            <div className="form-container">
              <div className="form-group">
                <div className="labels">
                    <span>금고출금

                    {
                    this.props.user && 
                      <span style={{padding: '15px', fontSize: '16px' ,color: '#FFC107'}}> 금고 보유금 : {ConverMoeny(this.props.user.point)}</span>
                    }
                    </span>
                </div>
                <div className="input-container">
                  <div className="infos">
                    <input className="form-control parsley-success" value={this.state.point} onChange={(e) => this.setState({point: Number(e.target.value),})} />
                  </div>
                </div>
              </div>
            </div>   
            
            <div className="modal-footer">
              <button type="submit" className="btn-style" onClick={this.handleChangeToPoint}>출금하기</button>
            </div> 

					</div>
				 </div>
			  </div>
		   </div>
		</div>
	 </div>


   <div className="modal-backdrop fade show"></div>

          </div>

        )}
      </Popup>
    );
  }
}

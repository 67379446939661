import React, { Component, useState , useEffect} from "react";

import { HashRouter as Router, Route, Switch } from "react-router-dom";

import { createGlobalStyle } from "styled-components";

import { makeStyles, withStyles } from "@material-ui/core/styles";

// import { Game } from "./main_new/game";
import { Game } from "./main_new/game";
import { Power } from "./main_new/power";

import { UserService } from "../service/user.service";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import { NotePopup } from "./share/notepopup";

import SoundPlays from "../SoundPlay";



const GlobalStyles = createGlobalStyle`
body{
  color: #FFC107;
  background-color: #000;
}

.body{
  color: #FFC107;
}

.MuiPaper-root {
  background-color: #000;
  color: #FFC107;
}

.popup-content {
  width: 100%;
  border : 'none'

}

.MuiToolbar-gutters{
  padding :0px
}

.popup-content {
  background: none;
  border : 'none'

} 

.react-confirm-alert-overlay {
  z-index:99999
}
`;

interface Props {
  authenticated: boolean;
  session: string;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;

  SetCookie: (name: string, data: any) => any;
  GetCookie: (name: string) => any;
}

interface State {
  user: {
    id: string;
    
    rolling: number;
    in_balance: number;
    balance: number;
    point: number;
    bankname: string;
    banknum: string;
    bankowner: string;

  };
  helpCount : number
}

async function sleep(ms: any) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export class Web extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);
    this.state = {
      user: {
        id: "",
        in_balance: 0,
        balance: 0,
        point: 0,
        rolling: 0,
        
        bankname: "",
        banknum: "",
        bankowner: "",
      },
      helpCount : 0,
    };

    if(isBrowser){
      setImmediate(async() => {

        try{
          for (; ;) {
            this.updateUser();
    
            await sleep(30000)
          }
    
        }
        catch(ex)
        {

        }
      });
      // this.updateUser();
    }
  }

  componentDidMount() {
    
  }

  updateUser = () => {
    this.userService.healthCheck().then((s: any) => {
      if (s.status === "success") {
        let user = {
          id: s.user.id,
          in_balance: s.user.in_balance,
          balance: s.user.balance,
          point: s.user.point,
          bankname: s.user.bankname,
          banknum: s.user.banknum,
          bankowner: s.user.bankowner,  
          rolling: s.user.rolling,  
          
        };

        if( s.helpCount > 0) {
          SoundPlays('helpCount')
        }
        if( s.noteCount > 0) {
          SoundPlays('helpCount')
        }

        if (JSON.stringify(user) !== JSON.stringify(this.state.user)) {
          this.setState({ user: user  });
        }
        
        if( this.state.helpCount  != s.helpCount ) {
          this.setState({ helpCount: s.helpCount,  });
        }
      } else {
        window.location.hash = "/";
      }
    });
  };

  render() {
    // if(isMobile){
    //   return (<></>)
    // }
    // require("./indexweb.css")


    return (
      <Router>
        <Switch>
    
          <Route exact path="/">
            <Game
              activeHelp={this.state.helpCount > 0}
              authenticated={this.props.authenticated}
              session={this.props.session}
              user={this.state.user}
              tryLogin={this.props.tryLogin}
              tryLoginOut={() => {
                this.setState({
                  user: {
                    id: "",
                    in_balance: 0,
                    balance: 0,
                    point: 0,
                    rolling: 0,
                    bankname: "",
                    banknum: "",
                    bankowner: "",
                  },
                });
                this.props.tryLoginOut();
              }}
            />
          </Route>
          <Route exact path="/power">
            <Power
              authenticated={this.props.authenticated}
              session={this.props.session}
              user={this.state.user}
              tryLogin={this.props.tryLogin}
              tryLoginOut={() => {
                this.setState({
                  user: {
                    id: "",
                    in_balance: 0,
                    balance: 0,
                    rolling: 0,
                    point: 0,
                    bankname: "",
                    banknum: "",
                    bankowner: "",
                  },
                });
                this.props.tryLoginOut();
              }}
            ></Power>
          </Route>

        </Switch>
        <GlobalStyles />

        {
          this.props.authenticated  && (
            <NotePopup
            SetCookie={this.props.SetCookie}
            GetCookie={this.props.GetCookie}
            ></NotePopup>
          )
        }

      
      </Router>
    );
  }
}

import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { confirmAlert } from "react-confirm-alert"; // Import

interface Props {}

interface State {

}


export class Footer extends Component<Props, State> {
   
  render() {
    

    return (
      
      <div>
        <footer className="footer-section">
            <div className="copyright">
               <div className="container">
                  <span>ⓒ COPYRIGHT WORLD SLOT 2020 ALL RIGHTS RESERVED.</span>
               </div>
            </div>
         </footer>
         <button className="scroll-top" style={{display: 'none'}}>
         <i className="fas fa-cloud-upload-alt" aria-hidden="true"></i>
         </button>
         <div className="sn-overlay"></div>
      </div>
      
    );
  }
}



import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import CopyrightIcon from "@material-ui/icons/Copyright";
// const drawerWidth = 440;
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";


import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoodIcon from '@material-ui/icons/Mood';
import DeleteIcon from '@material-ui/icons/Delete';



import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import { confirmAlert } from "react-confirm-alert"; // Import
import {
  ConvertDate,
  ConverMoeny,
  ConvertBalanceStateToText,
  popupView
} from "../../utility/help";
import { normalizeUnits } from "moment";
import { UserService } from "../../service/user.service";
import { SlotService } from "../../service/slot.service";

import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Point } from "./point";
import { Login } from "./login";
import { Comp } from "./comp";

import Popup from 'reactjs-popup';

import { BlueButton, GreenButton, RedButton, } from '../../utility/custom.button';

const CustomButton = styled(Button)`
  /* background-color: #6772e5; */
  /* color: #000;
  line-height: normal;
  font-size: 12px;
  font: bold; */

  box-shadow: inset 0px 1px 0px 0px #cf866c;
  background: linear-gradient(to bottom, #d0451b 5%, #bc3315 100%);
  background-color: #d0451b;
  border-radius: 3px;
  border: 1px solid #942911;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 16px;
  /* padding: 6px 24px; */
  text-decoration: none;
  width: 20%;
  text-shadow: 0px 1px 0px #854629;
`;

const styles = (theme: any) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
  },

  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    display: "inline",
    padding: "10px",
    fontSize: "16px",
  },
  investing: {
    fontSize: "18px",
  },
});

const CustomToolbar = styled(Toolbar)`
  /* .muitoolbar-regular : {
  } */
`;



async function sleep(ms: any) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}


interface Props {
  classes: any;
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;

  //   session: any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  isOpen: boolean;
  popupStatuses: string;
  expanded: string;
  notes: any;

}
class header extends Component<Props, State> {
  static propTypes: { classes: PropTypes.Validator<object> };
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      isOpen: false,
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: '',


    };

    this.handleUpdateNote();

      setImmediate(async() => {
        try{
          for (; ;) {
            this.handleUpdateNote();
    
            await sleep(10000)
          }
        }
        catch(ex)
        {

        }
      });
      // this.updateUser();
  }

  componentDidMount() {
 
    this.handleUpdateInBalance();
  }

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          balance: data.balance ?? 0,
        });
      } else {
      }
    });
  };

  

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {

        if(!this.state.isOpen){
          this.setState({
            postCount: data.count,
            note: data.note,
            isOpen: data.count > 0 ? true : false,
          });
  
        }
      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  
      
  handleReadNote = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_read_note(id).then((data: any) => {
        if (data.status === 'success') {
          let note = this.state.note.find((read: any) => read._id === id);
          if (note != null) {
            note.isRead = 'y';
            this.setState({
              note: this.state.note,
            });
          }
        }
      });
    }
  };

  

  do_del_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_del_all_note().then((data: any) => {

        window.location.reload();
      });
    }
  };



  do_read_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_read_all_note().then((data: any) => {

        window.location.reload();
      });
    }
  };

  

  handleDel = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_del_note(id).then((data: any) => {
        if (data.status === 'success') {
          this.setState({
            notes: data.note,
          });
        }
      });
    }
  };


  render() {
    const classes = this.props.classes;
    const user = this.props.user;
    const authenticated = this.props.authenticated;


    const RenderPopup = () => {
      
      if (this.state.popupStatuses === popupView.deposit) {
          
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })

                },
              },
            ],
          });
          return;
        }

        return <Deposit handleClose={this.handleClosePopup} handleChange={this.handleChangePopup}></Deposit>;
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })

                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw handleClose={this.handleClosePopup} user={this.props.user} handleChange={this.handleChangePopup}></Withdraw>
        );
      }
      if (this.state.popupStatuses === popupView.notice) {
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })
                },
              },
            ],
          });
          return;
        }
        return <Notice handleClose={this.handleClosePopup} handleChange={this.handleChangePopup}></Notice>;
      }

      if (this.state.popupStatuses === popupView.note) {
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })

                },
              },
            ],
          });
          return;
        }
        return <Reg handleClose={this.handleClosePopup}></Reg>;
      }

      if (this.state.popupStatuses === popupView.point) {
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })

                },
              },
            ],
          });
          return;
        }
        return <Point 
        handleClose={this.handleClosePopup}
        user={this.props.user}
        handleChange={this.handleChangePopup}
        ></Point>;
      }
      
      
      if (this.state.popupStatuses === popupView.reg) {
        
        return <Reg handleClose={this.handleClosePopup}></Reg>;
      }

      if (this.state.popupStatuses === popupView.login) {
        
        return <Login 
                    handleClose={this.handleClosePopup}
                    tryLogin={this.props.tryLogin}
                    tryLoginOut={this.props.tryLoginOut}
                    user={this.props.user}
                    authenticated={this.props.authenticated}
                    activeHelp={this.props.activeHelp}
                    ></Login>;
      }

      if (this.state.popupStatuses === popupView.help) {
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })

                },
              },
            ],
          });
          return;
        }
        return <Help handleClose={this.handleClosePopup} handleChange={this.handleChangePopup}></Help>;
      }
      if (this.state.popupStatuses === popupView.user) {
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })

                },
              },
            ],
          });
          return;
        }
        return <User handleClose={this.handleClosePopup} handleChange={this.handleChangePopup}></User>;
      }

      if (this.state.popupStatuses === popupView.comp) {
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.comp })

                },
              },
            ],
          });
          return;
        }
        return <Comp 
          user={this.props.user}
          handleClose={this.handleClosePopup} 
          handleChange={this.handleChangePopup}></Comp>;
      }


      return <div></div>;
    };

    const RendePopup = () => {
      if (0 < this.state.noteCount || this.state.isOpen === true) {
        const handleChange = (panel: string) => (
          event: React.ChangeEvent<{}>,
          isExpanded: boolean,
        ) => {
          let expanded =
            this.state.expanded === panel && panel !== '' ? '' : panel;
          this.setState({ expanded: expanded });

          this.handleReadNote(panel);
        };

        return (
          <Popup
            open={this.state.isOpen}
            onClose={() => {
              this.setState({ isOpen: false });
            }}
            contentStyle={{
              width: '80%',
              padding: '0px',
              background: 'none',
              border: 'none',
            }}
            overlayStyle={{
              border: 'none',
            }}
          >
            {(close) => (
              <div>
                <div
                  className="dark_title box_size sub_title"
                  style={{ background: 'black' }}
                >
                  
                </div>
                <div>
                  {this.state.note.map((info: any) => (
                    <ExpansionPanel
                      key={info._id}
                      expanded={this.state.expanded === info._id}
                      onChange={handleChange(info._id)}
                      style={{ margin: '0px' }}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        style={{ margin: '0px' }}
                      >
                        <Typography
                          style={{
                            width: '70%',
                            paddingTop: '10px',
                            textAlign: 'left',
                            color: '#ffeb3b'
                          }}
                        >
                          {info.title}
                        </Typography>
                        <Typography
                          style={{
                            width: '29%',
                            color: '#000',
                            paddingTop: '10px',
                          }}
                        >
                          {ConvertDate(info.regDate)}
                        </Typography>
                        <Typography style={{ width: '150px', color: '#000' }}>
                          {info.isRead === 'y' ? (
                            <GreenButton
                              color="secondary"
                              variant="contained"
                              startIcon={<MoodIcon />}
                            >
                              {'확인'}
                            </GreenButton>
                          ) : (
                              <BlueButton
                                color="secondary"
                                variant="contained"
                                startIcon={<MoodIcon />}
                              >
                                {'미확인'}
                              </BlueButton>
                            )}
                        </Typography>
                        <Typography style={{ width: '150px', color: '#000' }}>
                          {/* BlueButton, GreenButton, RedButton */}
                          <RedButton
                            variant="contained"
                            color="secondary"
                            disableElevation={false}
                            startIcon={<DeleteIcon />}
                            onClick={() => {
                              this.handleDel(info._id);
                            }}
                          >
                            삭제
                          </RedButton>
                        </Typography>
                      </ExpansionPanelSummary>{' '}
                      {/* <RedButton
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          this.handleDel(info._id);
                        }}
                      >
                        삭제하기
                      </RedButton>{' '} */}
                      <ExpansionPanelDetails>
                        <Typography>
                          <div
                            dangerouslySetInnerHTML={{ __html: info.text }}
                          ></div>
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                </div>
              </div>
            )
            }
          </Popup >
        );
      }
    };

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          
                  <div className="before-login active"  >
                     <div className="panel">
                        <button className="login-link" onClick={() => {
                                          this.setState({
                                            popupStatuses: popupView.login,
                                          });
                                        }}>
                        <i className="fas fa-user-circle desk-icon" aria-hidden="true"></i>
                        <i className="fas fa-unlock-alt mob-icon" aria-hidden="true"></i>
                        <span>로그인</span>
                        </button>
                        <button className="join-link orange-bg" onClick={() => {
                                          this.setState({
                                            popupStatuses: popupView.reg,
                                          });
                                        }}>
                        <i className="fas fa-user-plus" aria-hidden="true"></i>
                        <span>회원가입</span>
                        </button>
                     </div>
                  </div>

        );
      }

      return (
        
                <div className="after-login active">
                     <div className="sidebar-right">
                        <div className="al-inner">
                           <div className="al-cont">
                              <div className="labels">
                                 <i className="fas fa-user-tie" aria-hidden="true"></i>
                              </div>
                              <div className="info">
                                 <p>{user.id}&nbsp;<span className="symbol">님</span></p>
                              </div>
                           </div>
                           <div className="al-cont">
                              <div className="labels">
                                 <a  className="myPage-link"><i className="fa fa-envelope" aria-hidden="true"></i></a>
                              </div>
                              <div className="info">
                                 <button className="message-btn" data-toggle="modal" data-target=".myPageModal" onClick={()=>{this.setState({isOpen : true})}}>
                                 쪽지
                                 <span className="mess-count">(&nbsp;{this.state.postCount}&nbsp;)</span>
                                 </button>
                              </div>
                           </div>
                           <div className="al-cont">
                              <div className="labels">
                                 <i className="fa fa-coins" aria-hidden="true"></i>
                              </div>
                              <div className="info">
                                 <p className="player-balance wager-reload balance">{ConverMoeny(this.state.balance)}&nbsp;<span className="symbol">원</span></p>
                              </div>
                           </div>
                           <div className="al-cont btn-group" style={{width : '28%'}}>
                              {/* <button type="button" className="myPage-link">마이페이지</button> */}
                              <button type="button" className="logout-btn" onClick={() => {this.props.tryLoginOut();}}>로그아웃</button>
                           </div>
                        </div>
                     </div>
                     <div className="mobile">
                        <button className="mobile-btn right-menu-btn">
                        <i className="fas fa-user-tie icon" aria-hidden="true"></i>
                        <i className="fas fa-chevron-down over-icon" aria-hidden="true"></i>
                        </button>
                        <button className="mobile-btn logout-btn"><i className="fas fa-sign-out-alt" aria-hidden="true"></i></button>
                     </div>
                  </div>

      );
    };


    

    return (
      
      <header className="header-main">
        <div className="container">
              <a href="/" className="diamond-logo">
                  <img className="panel" src="/new/images/panel.png"/>
                  <img className="dias-lg" src="/new/images/diamond-lg.png?ver=1"/>
                  <div className="dias-sm">
                     <img className="diamond-img" src="/new/images/diamond-sm.png?ver=1"/>
                     <img className="glow" src="/new/images/glow.png"/>
                  </div>
                  <img className="diamond" src="/new/images/diamond.png?ver=1"/>
                  <img className="slot" src="/new/images/slot.png"/>
               </a>

               <ul className="bs-ul main-menu sidebar-left">
               <li onClick={() => {
                  this.setState({
                    popupStatuses: popupView.deposit,
                  });
                }}>
                    <a  className="deposit-link">
                    <i className="fas fa-university" aria-hidden="true"></i> 입금신청
                    </a>
                </li>
                <li onClick={() => {
                  this.setState({
                    popupStatuses: popupView.withdraw,
                  });
                }}>
                    <a  className="deposit-link">
                    <i className="fas fa-money-bill-wave" aria-hidden="true"></i> 출금신청
                    </a>
                </li>
                <li onClick={() => {
                  this.setState({
                    popupStatuses: popupView.point,
                  });
                }}>
                    <a  className="deposit-link">
                    <i className="fas fa-gem" aria-hidden="true"></i> 금고
                    </a>

                </li>

                <li onClick={() => {
                  this.setState({
                    popupStatuses: popupView.comp,
                  });
                }}>
                    <a  className="deposit-link">
                    <i className="fas fa-cart-plus" aria-hidden="true"></i> 콤프
                    </a>
                </li>
                
                <li onClick={() => {
                  this.setState({
                    popupStatuses: popupView.help,
                  });
                }}>
                  
                    <a  className={this.props.activeHelp ? 'deposit-link neon' : 'deposit-link'} >
                    <i className="fas fa-info" aria-hidden="true"></i> 고객센터
                    </a>
                </li>
                <li onClick={() => {
                  this.setState({
                    popupStatuses: popupView.user,
                  });
                }}>
                    <a  className="deposit-link">
                    <i className="fas fa-user" aria-hidden="true"></i> 마이페이지
                    </a>
                </li>
                <li onClick={() => {
                  this.setState({
                    popupStatuses: popupView.notice,
                  });
                }}>
                    <a  className="deposit-link">
                    <i className="fas fa-crown" aria-hidden="true"></i> 공지사항
                    </a>
                </li>

               </ul>

                {
                        authenticated ? (
                        <div className="bal-container"  style={{top : isMobile ? '0px' : '20px', right : isMobile ? '0px' : '200px', }}>
                          {RenderLogin()}
                        </div>
                        ) : (
                        <div className="bal-container"  style={{top : isMobile ? '0px' : '0px', right : isMobile ? '0px' : '200px', }}>
                          {RenderLogin()}
                        </div>
                          
                        )

                }                  
               <button className="left-menu-btn">
               <span></span>
               <span></span>
               <span></span>
               </button>

        </div>

        {RendePopup()}
        {RenderPopup()}
      </header>

      
    );
  }
}

header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export let Header = withStyles(styles, { withTheme: true })(header);
export default Header;
